import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  Container,
  Button,
  useColorModeValue,
  VStack,
  Divider
} from '@chakra-ui/react';
import Navbar from './Navbar';
import Footer from './Footer';
import LandingForm from './LandingForm';



const services = {
    'Modules on Offer': [
      { title: 'Elements of Logic 🧠', description: "Exploring the Principles of Critical Thinking", emphasis: true },
      { title: 'Manipulating Reality', description: "Understanding Media Influence and Public Perception", emphasis: true  },
      { title: 'Pioneers of Entrepreneurship 📈', description: "Introduction to Business Ventures", emphasis: true },
      { title: 'Resilient States 🇺🇳', description: "Navigating the Future of Statehood ", emphasis: true  }
    ]
    // Add more categories and services as needed
  };

const ModuleCard = ({ title, description, emphasis }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      p={6}
      my={7.5}
      rounded={'3xl'}
      align={'center'}
      justify={'center'}
      spacing={4}
      border={emphasis ? '1px' : '0px'}
      borderColor={emphasis ? '#204f99' : 'gray.100'}
      transform={emphasis ? 'scale(1.05)' : 'none'}
      _hover={{
        boxShadow: '3xl',
        transform: 'scale(0.99)',
        border: 'scale(1.5)',
        borderColor: '#204f99',
      }}
      transition={'transform 0.6s, box-shadow 0.2s, border-color 0.5s'}
    >
      <Heading size={'md'} textAlign="center" fontWeight="semibold">{title}</Heading>
      <Text textAlign="center" color={useColorModeValue('gray.600', 'gray.200')}>
        {description}
      </Text>
    </Stack>
  );
};


const Milestones = () => {
  return (
    <>
    <Navbar/>
    <Container maxW="container.xl" py={8} mb={12}>
      <Container maxW="container.lg">
    <Heading as="h1" size="xl" textAlign="center" mb={0} m={8} py={0} fontFamily="'Avenir Next', sans-serif" fontWeight="bold" color="#204f99">
    Academic Modules in partnership with Milestone Institute 
        </Heading>
        </Container>
        <Container maxW="container.md" py={2}>
        <Text textAlign="left" mb={6}>
        Lumo is excited to introduce Milan to Academic Modules, powered by Milestone Institute, in an exclusive partnership to set up students for academic success.
        </Text>
        </Container>
    <VStack spacing={12} px={{ base: '4', lg: '75' }}>
      {Object.entries(services).map(([category, services], index) => (
        <Box key={index} width="full" mb={10}>
          <Heading as="h2" size="md" textAlign="center" mb={6}>
            {category}
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {services.map((service, serviceIndex) => (
              <ModuleCard key={serviceIndex} {...service} />
            ))}
          </SimpleGrid>
        </Box>
      ))}
    </VStack>
    <Container maxW="container.md" py={2}>
    <Heading as="h2" size="md" textAlign="center" mb={6}>
      About Milestone Institute
    </Heading> 
    <Text textAlign="left" mb={6}>
        Milestone Institute of Advanced Studies is an academic enrichment centre for the gifted and talented. Their world-class talent enrichment programme has seen their students gain admissions to Oxbridge, Ivy Leagues, and many more prestigious universities around the world. Milestone is an internationally recognised hub for research, social action, and entrepreneurial initiatives, and working alongside Lumo, they wish to share their unique programme with students in Milan.
        </Text>
        <Text textAlign="left" mb={6}>
        The modules are taught by subject expert faculty and are delivered over 8 weeks in 50-60-minute-long classes, and the classes are conducted in a hybrid format and classrooms will have no more than 6 students.
These modules are specially crafted for students who wish to pursue their passions to an advanced level and there is something for everyone!
        </Text>
        </Container>
    </Container>
    <Footer/>
    </>
  );
};

export default Milestones;

