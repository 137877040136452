import React, { useState } from 'react';
import {
  Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
  Input, InputGroup, InputRightElement, useToast, ModalCloseButton
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from './AuthContext';

const LoginModal = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://api.lumo-edu.com/auth/login', {
        username,
        password
      }, {
        headers: {
          'Content-Type': 'application/json' // Ensuring the content type is set correctly
        }
      });


    } catch (error) {
      console.error('Login failed:', error);
      toast({
        title: "Login Failed",
        description: "Incorrect username or password.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top"
      });
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Modal isOpen={true} onClose={() => {}} isCentered size="xl">
      <ModalOverlay backdropFilter="blur(10px)"/>
      <ModalContent>
        <ModalHeader>Login to Your Account</ModalHeader>
        <ModalCloseButton isDisabled />
        <ModalBody pb={6}>
          <Input 
            placeholder="Username" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            mb={4} 
          />
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={toggleShowPassword}>
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleLogin} isLoading={isLoading}>
            Login
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
