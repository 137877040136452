import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, useToast, Heading, Textarea, Checkbox, Link } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import Navbar from './Navbar';


const LandingForm = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '', // Use 'message' for state management
  });

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, message } = formData;
    const formattedData = new FormData();
    formattedData.append('name', name);
    formattedData.append('email', email);
    formattedData.append('bio', message); // 'message' from state maps to 'bio' for the backend
    formattedData.append('subjects', 'N/A'); // Dummy value for 'subjects'

    // Dummy file content for 'file'
    const dummyFile = new Blob(['Dummy file content'], { type: 'text/plain' });
    formattedData.append('file', dummyFile, 'dummyfile.txt');

    try {
      const response = await fetch('https://api.lumo-edu.com/submit-form', {
        method: 'POST',
        body: formattedData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast({
        title: 'Inquiry Submitted',
        description: 'Your message has been successfully submitted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setFormData({ name: '', email: '', message: '' }); // Reset form state
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      setFormData({ name: '', email: '', message: '' });
      toast({
        title: 'Inquiry Submitted',
        description: 'Your message has been successfully submitted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="3xl" mx="auto" px={8} py={4} borderWidth="0px" borderRadius="3xl" boxShadow="2xl" background="white">
      <Heading as="h1" size="lg" textAlign="center">Get in touch!</Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="name" isRequired>
          <FormLabel my={3}>Name</FormLabel>
          <Input
            name="name"
            type="text"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="email" isRequired>
          <FormLabel my={3}>Email address</FormLabel>
          <Input
            name="email"
            type="email"
            placeholder="youremail@example.com"
            value={formData.email}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl id="agreement" isRequired>

</FormControl>
        <FormControl id="message" isRequired>
          <FormLabel my={3}>Message</FormLabel>
          <Textarea
            name="message" // Changed from 'bio' to 'message' to match form state
            placeholder="Your message here..."
            value={formData.message}
            onChange={handleChange}
            minHeight="100px"
          />
        </FormControl>
        <Checkbox name="agreement" onChange={(e) => setIsAgreed(e.target.checked)} my={3} colorScheme="blue">
    I consent to having my data processed according to the <Link color='#204f99' href={'/privacy'} isUnderlined>Privacy Policy</Link>
  </Checkbox>
        <Button my={3} colorScheme="blue" rightIcon={<EmailIcon />} isDisabled={!isAgreed} type="submit">Submit Inquiry</Button>
      </form>
    </Box>
    
  );
};

export default LandingForm;
