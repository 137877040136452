import React from 'react';
import { Box, Text, Flex, SimpleGrid, Image, Card, CardBody, Divider} from '@chakra-ui/react';
import Navbar from './Navbar';
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import Tutors from './Tutors';
import Home from './Home';
import About from './About';
import theme from "./Theme";
import Privacy from './Privacy';
import TutorApplicationForm from './TutorApplicationForm';
import { ChakraProvider } from "@chakra-ui/react"
import OurServices from './OurServices';
import './App.css';
import NewTutors from './NewTutors';
import AddTutorForm from './AddTutorForm';
import Dashboard from './dashboard';
import { AuthProvider } from './AuthContext';
import Milestones from './Milestones';


function App() {
  const appStyle = {
    maxHeight: '1000vh',
    backgroundPosition: 'right top'
  };
  return (
    <>
    <div style={appStyle} className="appBackground">
    <ChakraProvider theme={theme}>
      <AuthProvider>
      <Router>
      <Divider/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/Privacy" element={<Privacy/>} />
        <Route path="/About" element={<About/>} />
        <Route path="/Join" element={<TutorApplicationForm/>} />
        <Route path="/OurServices" element={<OurServices/>}/>
        <Route path="/tutors" element={<NewTutors/>}/>
        <Route path="/Dashboard" element={<Dashboard/>}/>
        <Route path="/Milestones" element={<Milestones/>}/>
      </Routes>
      </Router>
      </AuthProvider>
      </ChakraProvider>
      </div>
    </>
  );
}

export default App;